import  React, {useContext} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useLabelContext } from '../context/labelContext.js'
import { useListContext } from '../context/listContext.js'

function BasicSelect({src, tags, setTags, setSrc, label, setLabel}) {
    const handleChange = (e) => {
        setSrc(e.target.value)
    }
    const handleTagChange = (e) => {
        setTags(e.target.value)
    }
    const handleLabelChange = (e) => {
        setLabel(e.target.value)
    }
    const { labels } = useLabelContext();
  return (
    <Box sx={{ width: 250 }}>
      <FormControl fullWidth sx={{marginBottom:"10px"}}>
        <InputLabel id="demo-simple-select-label">Source</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={src}
          label="Source"
          onChange={handleChange}
        >
          <MenuItem value={''}>None</MenuItem>
          <MenuItem value={'Gamma'}>Gamma</MenuItem>
          <MenuItem value={'Delta'}>Delta</MenuItem>
          <MenuItem value={'Epsilon'}>Epsilon</MenuItem>
          <MenuItem value={'Zeta'}>Zeta</MenuItem>
          <MenuItem value={'Eta'}>Eta</MenuItem>
          <MenuItem value={'Theta'}>Theta</MenuItem>
          <MenuItem value={'Iota'}>Iota</MenuItem>
          <MenuItem value={'Kappa'}>Kappa</MenuItem>
          <MenuItem value={'Lambda'}>Lambda</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{marginBottom:"10px"}}>
        <InputLabel id="demo-simple-select">Tag</InputLabel>
        <Select
          labelId="demo-simple-select"
          id="demo-simple"
          value={tags}
          multiple
          label="Tag"
          onChange={handleTagChange}
        >
          <MenuItem value={''}>None</MenuItem>
          <MenuItem value={'error'}>Error</MenuItem>
          <MenuItem value={'incomplete'}>Incomplete</MenuItem>
          <MenuItem value={'duplicate'}>Duplicate</MenuItem>
          <MenuItem value={'long_name'}>Long Name</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="label-select">Label</InputLabel>
        <Select
          labelId="label-select"
          value={label}
          multiple
          label="Tag"
          onChange={handleLabelChange}
        >
      {labels.map(_label => <MenuItem value={_label}>{_label}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  );
}

export default function BasicMenu({anchorEl, handleClose}) {
  const open = Boolean(anchorEl);
  const { setListState, listState  } = useListContext();
  const [src, setSrc] = React.useState(listState?.filters?.source ?? '');
  const [tags, setTags] = React.useState(listState?.filters?.tags ?? []);
  const [label, setLabel] = React.useState(listState?.filters?.label ?? []);
   const handleApply = () => {
    setListState({...listState, filters:{...listState?.filters, source:src, tags: tags, label:label}})
        handleClose()
  }
    
  return (
    <div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      PaperProps={{
    style: {
      width: 350,
            display:'flex',
            justifyContent:'center'
    },
 }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <BasicSelect src={src} setSrc={setSrc} tags={tags} setTags={setTags} setLabel={setLabel} label={label}/>

      <Box sx={{display:'flex', marginTop:1, alignItems:'center', justifyContent:'center'}}>
          <Button onClick={handleApply} variant="contained">apply</Button>
      </Box>
      </Menu>
    </div>
  );
}
